import React from 'react'
import Head from 'next/head'
import Link from 'next/link'

export default function Page() {
    return (
        <div className='flex flex-col items-center justify-center min-h-screen pt-2'>
            <Head>
                <title>BreweryDB | Page Not Found</title>
                <link rel='icon' href='/favicon.png' />
            </Head>

            <main className='page'>
                <header className='w-full text-center'>
                    <h1 className='text-3xl text-navy font-bold'>
                        Page Not Found
                    </h1>
                    <p className='mt-3 text-2xl'>404</p>
                </header>

                <div className='container mt-6 pt-6 border-t-2 border-t-medium text-center'>
                    You don&apos;t have to{' '}
                    <Link href='/'>
                        <a className='font-bold'>go home</a>
                    </Link>
                    , but you can&apos;t stay here.
                </div>
            </main>
        </div>
    )
}
